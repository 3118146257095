import { Injectable } from '@angular/core';
import { ApiService } from '@libs/app.services/api.service';
import { IGameCategory } from '@libs/interfaces';
import { MapResponseData } from '@libs/rxjs/operators.rxjs';
import { WALLET_CODE } from '@libs/interfaces';

@Injectable({
  providedIn: 'root',
})
export class GamesOfficeService {
  constructor(public api: ApiService) {}
  categories() {
    return this.api.get(`games/categories`).pipe(MapResponseData);
  }
  update_category(cat: IGameCategory) {
    return this.api.post(`games/update/category`, cat).pipe(MapResponseData);
  }
  played(wallet = WALLET_CODE.BRL, offset = 0, limit = 10) {
    return this.api.get(`games/played/${wallet}/${offset}/${limit}`).pipe(MapResponseData);
  }
}
